// Prod - default values
let _wwwUrl = "https://www.addy.co";
let _apiUrl = "https://api.addy.co/api/v1";
let _stripeKey = 'pk_live_QjIkizUdDXHRQs9qRFNX3FS5';
let _cartCookieName = 'addy_cart_id';

// Dev/QA override
if (window.location.hostname === 'dashboard-dev.addy.co') {

  // Dev
  _wwwUrl = "http://dev.addy.co";
  _apiUrl = "http://api-dev.addy.co/api/v1";
  _stripeKey = 'pk_test_eCu3kiEHoZMvKsXMatlf79Rg';
  _cartCookieName = 'addy_cart_id_qa';

} else if (
  window.location.hostname === 'dashboard-qa.addy.co' ||
  window.location.hostname === 'checkout-qa.addy.co' ||
  window.location.hostname === 'localhost'
) {

  // QA
  _wwwUrl = "https://qa.addy.co";
  _apiUrl = "https://api-qa.addy.co/api/v1";
  _stripeKey = 'pk_test_eCu3kiEHoZMvKsXMatlf79Rg';
  _cartCookieName = 'addy_cart_id_qa';

}

export const wwwUrl = _wwwUrl;
export const apiUrl = _apiUrl;
export const stripeKey = _stripeKey;
export const cartCookieName = _cartCookieName;

export const authTimeout = 24 * 60 * 60 * 1000;

export const sessionCookieName = 'adverator_sess';
export const dashboardCookieName = 'addy_dash_token';

export const INTERCOM_APP_ID = 'q4d030fl';

export const accountLoginRedirectURL = '/orders/all';

export const HOTJAR_ID = '2659468';
export const HOTJAR_SNIPPET_VERSION = 6;
