import React from 'react';
import {useParams} from "react-router";
import {Page} from 'components/elements/common';
import {makeStyles} from '@material-ui/core/styles';
import {Box, CircularProgress, Dialog, Paper} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckoutBody from 'components/elements/CheckoutPage/CheckoutBody';
import CheckoutSummary from 'components/elements/CheckoutPage/CheckoutSummary';
import AuthenticateDialog from 'components/elements/CheckoutPage/AuthenticateDialog';
import DataController from 'lib/controllers/DataController.js';
import useFormControl from 'lib/hooks/useFormControl';
import {useSnackbar} from "notistack";
import useAppState from 'lib/hooks/useAppState.js';
import AgencyHeader from 'components/elements/CheckoutPage/AgencyHeader';
import {debounce, formatErrorObj} from "lib/functions";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  content: {
    flex: '1 1 auto',
    padding: 0,
    width: '100%',
    margin: 32,

    [theme.breakpoints.down('md')]: {
      margin: 24,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 8,
      width: '95%'
    }
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "80px",
    width: '100%',
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
    borderRadius: "10px",

    [theme.breakpoints.up('sm')]: {
      marginBottom: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px',
    }
  },
  //
  body: {
    borderRadius: '10px',
    display: 'flex',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  dialog: {
    width: 450,
    margin: 'auto',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
    }
  },
  iframeLoader: {
    position: 'absolute',
    width: 42,
    height: 42,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const defaultForm = {
  company_name: '',
  company_street: '',
  company_city: '',
  company_state: '',
  company_zip: '',
  company_phone: '',
  billing_contact_name: '',
  billing_contact_email: '',
  billing_contact_phone: '',
  billing_same: '',
  billing_street: '',
  billing_city: '',
  billing_state: '',
  billing_zip: '',
  billing_country: ''
};

function CheckoutPage() {
  const classes = useStyles();
  const {cartId} = useParams();
  const {enqueueSnackbar} = useSnackbar();

  const [cart, setCart] = React.useState(null);
  const [cartErrors, setCartErrors] = React.useState([]);
  const [dateErrors, setDateErrors] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [loadingCart, setLoadingCart] = React.useState(false);
  const [loadingPromoCode, setLoadingPromoCode] = React.useState(false);
  const [loadingPlaceOrder, setLoadingPlaceOrder] = React.useState(false);
  const [promoCodeError, setPromoCodeError] = React.useState(false);
  const [copyAddress, setCopyAddress] = React.useState(true);
  console.log('1::::copyAddress', copyAddress)
  const [paymentAddressError, setPaymentAddressError] = React.useState({});
  const {state} = useAppState();
  const authenticated = React.useMemo(() => !!state.token, [state.token]);

  const isCartProcessed = React.useMemo(() => cart && cart?.processed_at !== null, [cart?.processed_at]);
  const [iframeLoading, setIframeLoading] = React.useState(false);
  const [iframeUrl, setIframeUrl] = React.useState(null);
  const [open3ds, setOpen3ds] = React.useState(false);
  const paymentDataWithIntent = React.useRef(null);

  const getPaymentMethod = (cart) => cart?.is_invoice ? "invoice" : cart?.paymentmethod_id;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(getPaymentMethod(cart));
  const isInvoicePayment = React.useMemo(() => selectedPaymentMethod === 'invoice', [selectedPaymentMethod]);

  const steps = React.useMemo(() => {
    return [
      {
        title: 'Cart',
        label: 'Shopping Cart',
        is_active: false,
        btnLabel: 'Checkout',
        index: 0,
      },
      {
        title: 'Add-ons',
        label: 'Add-ons',
        is_active: false,
        btnLabel: 'Continue to Payment',
        index: 1
      },
      {
        title: 'Payment',
        label: 'Payment Method',
        is_active: false,
        btnLabel: 'Place Order',
        index: 2
      },
      {
        title: 'All Set',
        label: 'All Set',
        is_active: false,
        hideBtn: !isInvoicePayment,
        btnLabel: isInvoicePayment ? 'Pay All Invoices' : '',
        index: 3
      }
    ]
  }, [selectedPaymentMethod, isCartProcessed]);

  function scrollToTop(top = 0) {
    window.scrollTo({top, behavior: 'smooth'});
  }

  const {
    getFieldProps,
    formData,
    renderSubmitButton,
    setIsWorking
  } = useFormControl({
    defaultForm,
    loadValues: cart?.company ?? {}
  });

  const fetchCart = async () => {
    try {
      setLoadingCart(true);
      setOpen(false);
      const res = await DataController.getCartItems(cartId, authenticated);
      if (res?.success) {
        setCart(res?.data);
        setSelectedPaymentMethod(getPaymentMethod(res?.data))
      } else {
        if (!authenticated && res.message === "Sorry, you're not authorized to do that") {
          setOpen(true);
          return;
        }
        if (authenticated && res.message === "Sorry, you're not authorized to do that") {
          return;
        }
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      //TODO: handle error
    } finally {
      setLoadingCart(false);
    }
  }

  const updateItem = async (itemId, date_start) => {
    try {
      setLoading(true);
      const res = await DataController.updateCartItem(cartId, itemId, date_start);
      if (res?.success) setCart(res?.data);
      else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      //TODO: handle error
    } finally {
      setLoading(false);
    }
  }

  const deleteCartItem = async (cartId, itemId) => {
    try {
      setLoading(true);
      const res = await DataController.deleteCartItem(cartId, itemId);
      if (res?.success) setCart(res?.data);
      else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      //TODO: handle error
    } finally {
      setLoading(false);
    }
  }

  const updateCartPaymentType = async (cartId, type) => {
    try {
      setLoading(true);
      const res = await DataController.updateCartPaymentType(cartId, type);
      if (res?.success) setCart(res?.data);
      else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      // TODO: handle error
    } finally {
      setLoading(false);
    }
  };

  const addPromoCode = async (cartId, promoCode) => {
    try {
      setLoadingPromoCode(true);
      setPromoCodeError(false);
      const res = await DataController.addPromoCode(cartId, promoCode);
      if (res?.success) {
        setCart(res?.data)
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
        setPromoCodeError(true);
      }
    } catch (e) {
      //TODO: handle error
    } finally {
      setLoadingPromoCode(false);
    }
  }

  const removePromoCode = async () => {
    try {
      const res = await DataController.removePromoCode(cartId);
      if (res?.success) {
        setCart(res?.data);
        return;
      }
      if (res.message) {
        enqueueSnackbar(res.message, {variant: "error"});
      }
    } catch (e) {
      //TODO: handle error
    }
  };

  const insertAddon = async (cartId, itemId, item) => {
    try {
      setLoading(true);
      const res = await DataController.insertAddon(cartId, itemId, item);
      if (res?.success) setCart(res?.data);
      else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
        setCart(cart);
      }
    } catch (e) {
      //TODO: handle error
    } finally {
      setLoading(false);
    }
  }

  const updateAddon = async (cartId, itemId, addonId, item) => {
    try {
      setLoading(true);
      const res = await DataController.updateAddon(cartId, itemId, addonId, item);
      if (res?.success) setCart(res?.data);
      else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      //TODO: handle error
    } finally {
      setLoading(false);
    }
  }

  const updateAddonDebounce = React.useCallback(debounce(updateAddon, 500), []);

  const deleteAddon = async (cartId, itemId, addonId, item) => {
    try {
      setLoading(true);
      const res = await DataController.deleteAddon(cartId, itemId, addonId, item);
      if (res?.success) setCart(res?.data);
      else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      //TODO: handle error
    } finally {
      setLoading(false);
    }
  }

  const handleAddonChange = (cartId, itemId, prevSelected, selectedAddons) => {
    const toInsert = [];
    const toUpdate = [];
    const toDelete = new Map(prevSelected.map(i => [i.id, i]));
    selectedAddons.forEach(s => {
      s.items.forEach(item => {
        if (!item?.id && parseInt(item.qty) > 0) toInsert.push({...item, type: s.type});
        else {
          prevSelected.forEach(p => {
            if (p.id === item.id)
              if (
                item.qty > 0 &&
                (p.address_id !== item.address_id ||
                  p.qty !== item.qty)
              ) {
                toUpdate.push(item);
              }

          })
          parseInt(item.qty) > 0 && toDelete.delete(item?.id);
        }
      })
    });

    if (toInsert.length) {
      toInsert.forEach(addon => insertAddon(cartId, itemId, addon));
    }

    if (toUpdate.length) {
      toUpdate.forEach(addon => updateAddonDebounce(cartId, itemId, addon.id, addon));
    }

    if (toDelete.size) {
      toDelete.forEach((addon, _id) => deleteAddon(cartId, itemId, addon.id, addon));
    }
  }

  const selectPaymentMethod = async (cartId, selectedPaymentMethod, callback) => {
    try {
      setLoading(true);
      const res = await DataController.checkoutPayementMethod(cartId, selectedPaymentMethod);
      if (res?.success) {
        setCart(res?.data);
        callback && callback();
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      //TODO: handle error
    } finally {
      setLoading(false);
    }
  }

  const updatePaymentAddress = async (cartId) => {
    setLoadingPlaceOrder(true)
    try {
      console.log('1_controller------formData', formData?.billing_same)
      console.log('2::::copyAddress', copyAddress)
      formData.billing_same = copyAddress ? 1 : 0;
      console.log('2_controller------formData', formData?.billing_same)
      const res = await DataController.checkoutPaymentAddress(cartId, formData);
      if (res?.success) {
        setCart(res?.data);
        setPaymentAddressError({});
        return true;
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
        setPaymentAddressError(formatErrorObj(res.errors))
        return false;
      }

    } catch (e) {
      //TODO: handle error
    } finally {
      setLoadingPlaceOrder(false)
    }
  }

  const claimCart = async () => {
    try {
      const res = await DataController.checkoutClaimCart(cartId);
      if (res?.success) {
        setCart(res?.data);
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      //handle error
    } finally {
    }
  }

  const updateCheckoutCompany = async (companyId) => {
    try {
      const res = await DataController.updateCheckoutCompany(cartId, companyId);
      if (res?.success) {
        setCart(res?.data);
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (e) {
      //handle error
    } finally {
    }
  }

  const checkoutTransact = async () => {
    setLoadingPlaceOrder(true)
    try {
      const paymentData = paymentDataWithIntent?.current ?? {};
      const res = await DataController.checkoutTransact(cartId, paymentData);
      if (res?.success) {
        setCart(res?.data);
        setOpen3ds(false);
        switchStep(steps[3]);
        return true;
      } else if (res['3ds']) {
        paymentDataWithIntent.current = {paymentintent: res['3ds'].paymentintent_id};
        setIframeUrl(res['3ds'].next_action.redirect_to_url.url);
        window.addEventListener('message', on3dsComplete);
        setIframeLoading(true);
        setOpen3ds(true);
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
        return false;
      }
    } catch (e) {
      //handle error
    } finally {
      setLoadingPlaceOrder(false)
    }
  }

  const handlePlaceOrder = async (checkAddress = true) => {
    if (checkAddress) {
      const isAddressUpdateSuccess = await updatePaymentAddress(cartId);
      if (!isAddressUpdateSuccess) {
        scrollToTop(400);
        return;
      }
    }
    try {
      const isTransactionSuccessful = await checkoutTransact();
      if (!isTransactionSuccessful) {
        scrollToTop(400);
        return;
      }
      switchStep(steps[3]);
      scrollToTop();
    } catch (e) {
      //handle error
    } finally {
    }
  }

  async function handlePayClick({token, nickname, accountId, tokenType}) {
    const isAddressUpdateSuccess = await updatePaymentAddress(cartId);
    if (!isAddressUpdateSuccess) {
      scrollToTop(400);
      return;
    }

    let method = 'createPaymentMethod';
    let payload = {
      type: tokenType,
      token: token,
      nickname
    }

    if (tokenType === 'ach') {
      method = 'createPaymentMethodAch';
      payload = {
        account_id: accountId,
        public_token: token,
      }
    }
    setLoadingPlaceOrder(true)
    DataController[method](payload).then(res => {
      if (res && res.success) {
        selectPaymentMethod(cartId, res.data.id, () => handlePlaceOrder(false));
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    }).catch((e) => {
      setLoadingPlaceOrder(false)
    });
  }

  async function validateCart() {
    setCartErrors([]);
    try {
      const res = await DataController.validateCart(cartId);
      if (!res.success) {
        if (res.message) {
          enqueueSnackbar(res.message, {variant: "error"});
        }
        if (Object.keys(res.errors).length) {
          setCartErrors(res.errors);
        }
      }
      return res.success;
    } catch (e) {
      //TODO: handle error
    }
  }

  async function validateDates() {
    setDateErrors([]);
    try {
      const res = await DataController.validateDates(cartId);
      if (!res.success) {
        if (res.message) {
          enqueueSnackbar(res.message, {variant: "error"});
        }
        if (Object.keys(res.errors).length) {
          setDateErrors(res.errors);
        }
      }
      return res.success;
    } catch (e) {
      //TODO: handle error
    }
  }

  React.useEffect(() => setTimeout(() => fetchCart()), [state.token]);

  React.useEffect(() => {
    const isInvoicePayment = cart?.is_invoice;
    isCartProcessed && switchStep({
      ...steps[3],
      btnLabel: "Pay All invoices",
      hideBtn: !isInvoicePayment,
      is_active: true,

    })
  }, [isCartProcessed]);

  React.useEffect(() => {
    if (selectedPaymentMethod !== null
      && !(cart?.is_invoice && selectedPaymentMethod === "invoice")
      && selectedPaymentMethod !== cart?.paymentmethod_id
    ) {
      selectPaymentMethod(cartId, selectedPaymentMethod)
    }
  }, [selectedPaymentMethod])

  const gotoStep = isCartProcessed ? steps[3] : steps[0];
  const [currStep, setCurrentStep] = React.useState(gotoStep);
  const switchStep = newStep => {
    currStep.completed = true;
    steps.forEach(step => step.is_active = step.title === newStep.title);
    setCurrentStep(newStep);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onDone = () => {
    onClose();
    //setTimeout(() => fetchCart());
  };

  const onClose3ds = () => {
    setOpen3ds(false);
  }

  const on3dsComplete = (ev) => {
    if (ev.data === '3DS-authentication-complete') {
      checkoutTransact();
    }
  }

  const showAgencyHeader = () => {
    return state?.userData.is_agency && cart && currStep.index !== 0 && currStep.index !== 3
  }

  React.useEffect(() => {
    return () => {
      window.removeEventListener('message', on3dsComplete);
    }
  }, []);

  return (
    <Page className={classes.root}>
      <Box className={classes.content}>
        {showAgencyHeader() &&
          <AgencyHeader
            companyId={cart?.companyid}
            className={classes.container}
            updateCheckoutCompany={updateCheckoutCompany}
          />
        }

        <Paper top="160px" className={classes.body}>
          {!loadingCart ? (
            <CheckoutBody
              items={cart?.contents?.items}
              updateItem={updateItem}
              steps={steps}
              currStep={currStep}
              switchStep={switchStep}
              handleAddonChange={handleAddonChange}
              getFieldProps={getFieldProps}
              formData={formData}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              errors={paymentAddressError}
              isInvoicePayment={isInvoicePayment}
              isCartProcessed={isCartProcessed}
              cart={cart}
              deleteCartItem={deleteCartItem}
              cartErrors={cartErrors}
              dateErrors={dateErrors}
              handlePayClick={handlePayClick}
              setCopyAddress={setCopyAddress}
              copyAddress={copyAddress}
            />
          ) : (
            <Skeleton animation="wave" variant="rect" width="60%" height="600px"/>
          )
          }
          {!loadingCart ? (
            <CheckoutSummary
              cart={cart}
              updateCartPaymentType={updateCartPaymentType}
              addPromoCode={addPromoCode}
              switchStep={switchStep}
              handlePlaceOrder={handlePlaceOrder}
              currStep={currStep}
              steps={steps}
              claimCart={claimCart}
              validateCart={validateCart}
              validateDates={validateDates}
              setCart={setCart}
              loadingPromoCode={loadingPromoCode}
              promoCodeError={promoCodeError}
              removePromoCode={removePromoCode}
              isInvoicePayment={isInvoicePayment}
              loadingPlaceOrder={loadingPlaceOrder}
            />
          ) : (
            <Skeleton animation="wave" variant="rect" width="40%" height="600px"/>
          )}

          <Dialog
            open={open}
            onClose={onClose}
            className={classes.dialog}
          >
            <AuthenticateDialog onDone={onDone} onClose={onClose}/>
          </Dialog>

          <Dialog
            open={open3ds}
            onClose={onClose3ds}
          >
            {iframeLoading &&
              <Box className={classes.iframeLoader}>
                <CircularProgress color="secondary"/>
              </Box>
            }
            <iframe src={iframeUrl} width="100%" height="400" frameBorder="0" target="_parent" onLoad={() => {
              setIframeLoading(false)
            }}></iframe>
          </Dialog>
        </Paper>
      </Box>
    </Page>
  );
}

export default CheckoutPage;
